import {
  StyleParamType,
  createStylesParams,
  wixFontParam,
  wixColorParam,
} from '@wix/tpa-settings';
import { IChallengeStyles } from './challengeStyles.types';
import { fontValueFix } from '../../stylesParams';

export const challengeStyles = createStylesParams<IChallengeStyles>({
  mobCardBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  mobDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  mobNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  mobParticipantsAndDurationSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobPriceSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobButtonSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  categoriesTabsMobileFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  categoriesTabsMobileFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  categoriesTabsMobileBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  categoriesTabsMobileBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  categoriesTabsMobileBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  categoriesTabsMobileTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
});
