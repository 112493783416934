import React from 'react';

import loadable from '@wix/yoshi-flow-editor/loadable';

import { withProviders } from '../../../contexts/main/withProviders';
import { FCWithChildren } from '@wix/challenges-web-library';

const RootWidget = loadable(() => {
  return import(/* webpackChunkName: "MAProgramList" */ './Root');
});

const Widget: FCWithChildren<any> = ({ isRendered, ...props }) => {
  if (!isRendered) {
    return null;
  }

  return <RootWidget {...props} />;
};

export default withProviders(Widget);
