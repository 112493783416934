import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';

export function getActiveParticipantsNumber(challenge: Challenge) {
  return challenge.participantsSummary.participantsNumber || 0;
}

export function getParticipantsNumber(challenge: Challenge): number {
  return (
    getActiveParticipantsNumber(challenge) +
    (challenge?.participantsSummary?.autoRemovedCount || 0)
  );
}
