import {
  Trigger,
  AccessType,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { RolesState } from '../components/ChallengesPage/Widget/settingsEvents';

export const ProgramRewardTrigger = {
  ...Trigger,
} as const;

export const ProgramAccessType = {
  ...AccessType,
} as const;

export type ProgramRewardTriggerType =
  (typeof ProgramRewardTrigger)[keyof typeof ProgramRewardTrigger];

export const ParticipantRoles = {
  ...RolesState,
} as const;
