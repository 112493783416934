import { LayoutSelectedTab } from '../Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';

export enum CategoriesTabsState {
  Regular = 'regular',
  Hover = 'hover',
  Selected = 'selected',
}

export enum ButtonState {
  Default = 'default',
  Hover = 'hover',
}

export enum RolesState {
  Visitor = 'Visitor',
  Participant = 'Participant',
}

export interface ISettingsEvents {
  buttonState: ButtonState;
  pageRole: RolesState;
  LayoutSelectedTab: LayoutSelectedTab;
  sidebarLayoutMobileScreen: string;
  categoriesTabsState: CategoriesTabsState;
}
