enum DebugLevel {
  TRACE = 'trace',
  LOG = 'log',
}

const level = '__debug_opg_level__';
const debugParam = 'debug-opg';
const getDebugLevel = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  let debugLevel = window.localStorage.getItem(level);
  if (!debugLevel) {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has(debugParam)) {
      debugLevel = searchParams.get(debugParam) || DebugLevel.LOG;
      window.localStorage.setItem(level, debugLevel);
    }
  }
  return debugLevel;
};

export const debug = (...args: any[]) => {
  const debugLevel = getDebugLevel();
  if (!debugLevel) {
    return;
  }
  if (debugLevel === DebugLevel.TRACE) {
    console.trace(...args);
    return;
  }
  console.log(...args);
};
